import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  dropZoneField,
  checkboxField,
  colorPicker,
  textBlock,
} from '@/utils/fields.js'
import { isDirector } from '@/utils/permissions'
import { stringAction } from '@/utils/actions'
import { required, hasDate, hasTime, nameLength } from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  id: uuidv4(),
  path: 'edit',
  name: 'Тарифы',
  type: 'FormRates',
  detail: true,
  alias: 'object',
  active: false,
  isShow: {
    value: true,
    condition: [
      // {
      //   permissions: [1, 15, 3, 4, 8, 17],
      //   type: false,
      // },
      // {
      //   mainData: 'type',
      //   value: [1, 12],
      //   type: false,
      // },
      //1,6,7 старые
      // 1,2,12 вовые
      {
        funcCondition: (ctx) => {
          console.log(ctx)
          if (
            [1, 3, 4, 8, 15, 17].includes(ctx.store.state.user.permission_id)
          ) {
            if (
              ctx.mainData.direction_json?.includes(2) &&
              ![1, 2, 12].includes(ctx.mainData.type)
            )
              return true
            if (
              ctx.mainData.direction_json?.includes(1) ||
              ctx.mainData.direction_json?.includes(6) ||
              ctx.mainData.direction_json?.includes(7)
            )
              return true
          }
          return false
          // return (
          //   [1, 3, 4, 8, 15, 17].includes(
          //     ctx.store.state.user.permission_id
          //   ) &&
          //   (ctx.mainData.direction_json.includes(1) ||
          //     ctx.mainData.direction_json.includes(6) ||
          //     ctx.mainData.direction_json.includes(7) ||
          //     (ctx.mainData.direction_json.includes(2) &&
          //       ![1, 2, 12].includes(ctx.mainData.type)))
        },
      },
    ],
  },
  actions: [
    stringAction({
      text: 'Сохранить',
      type: 'submit',
      module: '',
      name: 'saveForm',
      nextForm: true,
    }),
  ],
}
